<div class="container">
  <div class="row mb-2">
    <div class="col"><h1>Draugijos gyvūnai</h1></div>
  </div>

  <div class="row mb-2" *ngIf="animals">
    <div class="col-md-12">
      <div class="form-check form-check-inline" *ngFor="let status of statuses">
        <input
          class="form-check-input"
          type="checkbox"
          ng-attr-id="status"
          [checked]="statusFilter.indexOf(status) !== -1"
          (click)="toggleStatus(status)"
          ng-attr-value="option1"
        />
        <label class="form-check-label" ng-attr-for="status"
          >{{translateStatus(status)}}</label
        >
      </div>
    </div>
  </div>

  <div class="row" *ngIf="animals">
    <div class="col-md-12">
      <nav aria-label="Page navigation example" *ngIf="animals">
        <ul class="pagination">
          <li class="page-item" *ngIf="animals.page > 1">
            <a class="page-link" href="#" (click)="load(animals.page - 1)"
              >Praeitas puslapis</a
            >
          </li>
          <li
            [class.active]="page === animals.page"
            class="page-item"
            *ngFor="let page of pages"
          >
            <a class="page-link" href="#" (click)="load(page)">{{page}}</a>
          </li>
          <li
            class="page-item"
            *ngIf="animals.page < animals.totalItems / animals.itemsPerPage"
          >
            <a class="page-link" href="#" (click)="load(animals.page + 1)"
              >Kitas puslapis</a
            >
          </li>
        </ul>
      </nav>
    </div>
  </div>

  <div class="row mb-4" *ngIf="animals">
    <div class="col-md-12">
      <span>Rasta: {{animals.totalItems}} gyvūnų</span>
    </div>
  </div>

  <div class="row" *ngIf="animals">
    <div class="col-md-4" *ngFor="let animal of animals.data">
      <div class="card mb-4 box-shadow">
        <img
          class="card-img-top"
          data-src="https://api.gyvunuglobosdraugija.lt/v1/pictures/{{animal.pictureId}}/thumbnail"
        />
        <div class="card-body">
          <p class="card-text">
            <span> #{{animal.id}}</span>
            <span> {{translateStatus(animal.status)}}</span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="animals">
    <div class="col-md-12">
      <nav aria-label="Page navigation example" *ngIf="animals">
        <ul class="pagination">
          <li class="page-item" *ngIf="animals.page > 1">
            <a class="page-link" href="#" (click)="load(animals.page - 1)"
              >Praeitas puslapis</a
            >
          </li>
          <li
            [class.active]="page === animals.page"
            class="page-item"
            *ngFor="let page of pages"
          >
            <a class="page-link" href="#" (click)="load(page)">{{page}}</a>
          </li>
          <li
            class="page-item"
            *ngIf="animals.page < animals.totalItems / animals.itemsPerPage"
          >
            <a class="page-link" href="#" (click)="load(animals.page + 1)"
              >Kitas puslapis</a
            >
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
