import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AnimalStatus, GetAnimalsDto } from "./types/http";

@Injectable({
  providedIn: "root",
})
export class AnimalService {
  constructor(private http: HttpClient) {}

  getAnimals(page: number, statuses: AnimalStatus[]) {
    let filters: string[] = [];

    if (statuses.length > 0) {
      filters.push(`status(in:"${statuses.join(",")}")`);
    }

    let url = `https://api.gyvunuglobosdraugija.lt/v2/public-client/Animals?page=${page}`;

    if (filters.length > 0) {
      url += `&filter=${filters.join(",")}`;
    }

    return this.http.get<GetAnimalsDto>(url, {
      headers: { "x-api-key": "e7e25810-a618-4191-a02c-6bb46c1541b4" },
    });
  }
}
