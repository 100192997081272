import { Component, OnInit } from "@angular/core";
import { AnimalService } from "../animal.service";
import { AnimalStatus, GetAnimalsDto } from "../types/http";

@Component({
  selector: "app-animal-list",
  templateUrl: "./animal-list.component.html",
  styleUrls: ["./animal-list.component.scss"],
})
export class AnimalListComponent implements OnInit {
  public animals: GetAnimalsDto | null = null;
  public pages: number[] | null = null;

  public statuses: AnimalStatus[] = Object.keys(AnimalStatus)
    .filter(k => k !== AnimalStatus.Died) as AnimalStatus[];

  public statusFilter: AnimalStatus[] = [
    AnimalStatus.QueuedForAdoption,
  ];

  constructor(private animalService: AnimalService) {}

  load(page: number): void {
    this.animalService.getAnimals(page, this.statusFilter).subscribe((x) => {
      this.animals = x;
      this.pages = Array(Math.ceil(x.totalItems / x.itemsPerPage))
        .fill(0)
        .map((_, i) => i + 1);
    });
  }

  toggleStatus(status: AnimalStatus) {
    if (this.statusFilter.indexOf(status) === -1) {
      this.statusFilter.push(status);
    } else {
      this.statusFilter = this.statusFilter.filter((x) => x !== status);
    }

    if (this.animals) {
      this.load(this.animals?.page);
    }
  }

  // This is not the best way to translate. If i18n gets in place - should be refactored
  translateStatus(status: AnimalStatus) {
    switch (status) {
      case AnimalStatus.Adopted:
        return "Padovanotas";
      case AnimalStatus.Died:
        return "Miręs";
      case AnimalStatus.Missing:
        return "Dingęs";
      case AnimalStatus.QueuedForAdoption:
        return "Dovanojamas";
      case AnimalStatus.Returned:
        return "Grąžintas";
      case AnimalStatus.Sheltering:
        return "Globojamas";
      case AnimalStatus.TrapedNeuteredReleased:
        return "PSP";
    }
  }

  ngOnInit(): void {
    this.load(1);
  }
}
