<div class="container">
  <div class="row">
    <div class="col">
      <h5>Kontaktai</h5>
      Pelno nesiekianti organizacija VšĮ „Mažeikių gyvūnų globos draugija“, www.mazeikiugyvunai.lt <br />
      Paramos gavėjo kodas: 302551425 <br />
      Banko sąskaita: LT 41 7300 0101 2453 5678 <br />
      Paypal: <a href="https://paypal.me/mazeikiugyvunai">https://paypal.me/mazeikiugyvunai</a> <br />
    </div>
  </div>
</div>
