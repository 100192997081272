import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AnimalListComponent} from './animal-list/animal-list.component';
import {ContactsComponent} from './contacts/contacts.component';
import {ReportComponent} from './report/report.component';
import {CharityComponent} from './charity/charity.component';

const routes: Routes = [
  { path: 'animals', component: AnimalListComponent },
  { path: 'contacts', component: ContactsComponent },
  { path: 'report', component: ReportComponent },
  { path: 'charity', component: CharityComponent },
  { path: '**', component:  AnimalListComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
