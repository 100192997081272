export enum AnimalStatus {
  Sheltering = "Sheltering",
  QueuedForAdoption = "QueuedForAdoption",
  Adopted = "Adopted",
  Returned = "Returned",
  Died = "Died",
  Missing = "Missing",
  TrapedNeuteredReleased = "TrapedNeuteredReleased",
}

export type AnimalDto = {
  id: number;
  registrationDate: string;
  pictureId: number;
  status: AnimalStatus;
};

export type GetAnimalsDto = {
  totalItems: number;
  page: number;
  itemsPerPage: number;
  data: AnimalDto[];
};
