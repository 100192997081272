<div class="container">
  <h2>2023 metų statistika</h2>
  <div class="row">
    <div class="col-8">
      <table class="table table-bordered">
        <tbody>
        <tr class="bg-dark text-white">
          <td>Mažeikių gyvūnų globos draugija</td>
          <td>2023</td>
        </tr>
        <tr class="bg-dark text-white">
          <td colspan="2">Priimta atsisakytų</td>
        </tr>
        <tr>
          <td>Šunų</td>
          <td>23</td>
        </tr>
        <tr>
          <td>Kačių</td>
          <td>99</td>
        </tr>
        <tr class="bg-dark text-white">
          <td colspan="2">Eutanizuota / Mirė</td>
        </tr>
        <tr>
          <td>Šunų</td>
          <td>0</td>
        </tr>
        <tr>
          <td>Kačių</td>
          <td>11</td>
        </tr>
        <tr class="bg-dark text-white">
          <td colspan="2">Rado naujus namus</td>
        </tr>
        <tr>
          <td>Šunų</td>
          <td>22</td>
        </tr>
        <tr>
          <td>Kačių</td>
          <td>93</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <h2>2022 metų statistika</h2>
  <div class="row">
    <div class="col-8">
      <table class="table table-bordered">
        <tbody>
        <tr class="bg-dark text-white">
          <td>Mažeikių gyvūnų globos draugija</td>
          <td>2022</td>
        </tr>
        <tr class="bg-dark text-white">
          <td colspan="2">Priimta atsisakytų</td>
        </tr>
        <tr>
          <td>Šunų</td>
          <td>22</td>
        </tr>
        <tr>
          <td>Kačių</td>
          <td>102</td>
        </tr>
        <tr class="bg-dark text-white">
          <td colspan="2">Eutanizuota / Mirė</td>
        </tr>
        <tr>
          <td>Šunų</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Kačių</td>
          <td>14</td>
        </tr>
        <tr class="bg-dark text-white">
          <td colspan="2">Rado naujus namus</td>
        </tr>
        <tr>
          <td>Šunų</td>
          <td>22</td>
        </tr>
        <tr>
          <td>Kačių</td>
          <td>69</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <h1>2021 metų ataskaita</h1>
  <div class="row">
    <div class="col-8">
      <table class="table table-bordered">
        <tbody>
        <tr class="bg-dark text-white">
          <td>Mažeikių gyvūnų globos draugija</td>
          <td>2021</td>
        </tr>
        <tr class="bg-dark text-white">
          <td colspan="2">Priimta atsisakytų</td>
        </tr>
        <tr>
          <td>Šunų</td>
          <td>48</td>
        </tr>
        <tr>
          <td>Kačių</td>
          <td>132</td>
        </tr>
        <tr class="bg-dark text-white">
          <td colspan="2">Eutanizuota</td>
        </tr>
        <tr>
          <td>Šunų</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Kačių</td>
          <td>6</td>
        </tr>
        <tr class="bg-dark text-white">
          <td colspan="2">Rado naujus namus</td>
        </tr>
        <tr>
          <td>Šunų</td>
          <td>38</td>
        </tr>
        <tr>
          <td>Kačių</td>
          <td>73</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <h1>2020 metų ataskaita</h1>
  <div class="row">
    <div class="col-8">
      <table class="table table-bordered">
        <tbody>
        <tr class="bg-dark text-white">
          <td>Mažeikių gyvūnų globos draugija</td>
          <td>2020</td>
        </tr>
        <tr class="bg-dark text-white">
          <td colspan="2">Priimta atsisakytų</td>
        </tr>
        <tr>
          <td>Šunų</td>
          <td>104</td>
        </tr>
        <tr>
          <td>Kačių</td>
          <td>168</td>
        </tr>
        <tr class="bg-dark text-white">
          <td colspan="2">Eutanizuota</td>
        </tr>
        <tr>
          <td>Šunų</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Kačių</td>
          <td>23</td>
        </tr>
        <tr class="bg-dark text-white">
          <td colspan="2">Grąžinta savininkams</td>
        </tr>
        <tr>
          <td>Šunų</td>
          <td>5</td>
        </tr>
        <tr>
          <td>Kačių</td>
          <td>3</td>
        </tr>
        <tr class="bg-dark text-white">
          <td colspan="2">Rado naujus namus</td>
        </tr>
        <tr>
          <td>Šunų</td>
          <td>98</td>
        </tr>
        <tr>
          <td>Kačių</td>
          <td>99</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
