<div class="container">

  <div class="row">
    <div class="col-12">
      <h1>1,2% GPM parama</h1>

      <p>
        Organizacija: <span>"Mažeikių gyvūnų globos draugija"</span> <br/>
        Paramos gavėjo kodas: <span>302551425</span> <br/>
        IBAN Sąskaita: <span>LT 41 7300 0101 2453 5678</span> <br/>
        Tel. <a href="tel:+37069981662">+370 699 81662</a> <br/>
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <h1>Kaip skirti paramą</h1>
      <img src="/assets/images/step1.jpeg"/>
      <img src="/assets/images/step2.jpeg"/>
      <img src="/assets/images/step3.jpeg"/>
      <img src="/assets/images/step4.jpeg"/>

    </div>
  </div>
</div>
