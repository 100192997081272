
<!-- Toolbar -->
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" routerLink="/">Mažeikių gyvūnai</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" routerLink="/">Pradžia <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/contacts">Kontaktai</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/report">Metinės ataskaitos</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/charity">Skirk 1,2% Parama</a>
      </li>
    </ul>
  </div>
</nav>
<main role="main">
  <router-outlet></router-outlet>
</main>

<footer class="text-muted mt-5">
  <div class="container">
    Pelno nesiekianti organizacija VšĮ „Mažeikių gyvūnų globos draugija“, www.mazeikiugyvunai.lt <br/>
    Paramos gavėjo kodas: 302551425 Banko sąskaita: LT 41 7300 0101 2453 5678 <br/>
    Paypal: <a href="https://paypal.me/mazeikiugyvunai">https://paypal.me/mazeikiugyvunai</a>
  </div>
</footer>
