import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AnimalListComponent } from "./animal-list/animal-list.component";
import { ContactsComponent } from "./contacts/contacts.component";
import { ReportComponent } from "./report/report.component";
import { CharityComponent } from "./charity/charity.component";
import { HttpClientModule } from "@angular/common/http";

@NgModule({
  declarations: [
    AppComponent,
    AnimalListComponent,
    ContactsComponent,
    ReportComponent,
    CharityComponent,
  ],
  imports: [BrowserModule, HttpClientModule, AppRoutingModule, NgbModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
